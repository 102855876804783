import { TradesSetup } from "../types/trades";

export const tradeService = {
  tradeSetup: async function (payload: TradesSetup) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/v2/trade/setup?rr_token=${lsToken}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  createTrade: async function (payload: any) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/v2/trade?rr_token=${lsToken}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  completeTrade: async function (payload: any) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/v2/direct_trade?rr_token=${lsToken}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  completeArtifactTrade: async function (payload: any) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/v2/artifact_trade?rr_token=${lsToken}`, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  getTrades: async function () {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/v2/trades?rr_token=${lsToken}`, {
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
  cancelTrade: async function (trade_id: string) {
    let lsToken;
    try {
      lsToken = localStorage.getItem('rr_token');
    } catch(e) {
      localStorage.removeItem('rr_token');
      // hack to redirect back to page
      //eslint-disable-next-line
      window.location.href = window.location.href;
    }
    return fetch(`${process.env.REACT_APP_IM_API_URL}/api/trade/cancel?rr_token=${lsToken}&trade_id=${trade_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    })
      .then((response) => {
        if(response && response.status && response.status === 403) {
          localStorage.removeItem('rr_token');
          // hack to redirect back to page
          //eslint-disable-next-line
          window.location.href = window.location.href;
        } else if (response && response.status && response.status === 401) {
          window.location.href = '/forbidden';
        }
        return response.json();
      })
      .then((data) => {
        if(data && data.error) {
          return {error: true, data}
        }
        return data;
      })
      .catch((error) => {
        return {error: true, data: error};
      });
  },
}