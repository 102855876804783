import './chat.scss';
import React, { useCallback, useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader/AppHeader";
import { chatService } from "../../services/chat";
import { Chatbox, Session } from "@talkjs/react";
import { Button, CircularProgress, Snackbar } from "@mui/material";

type ChatConversation = {
  id: string;
  subject: string;
}

type BlockedUser = {
  _id: string;
  display_name: string;
}

export function Chat() {
  const [isLoading, setIsLoading] = useState(false);
  const [conversations, setConversations] = useState<ChatConversation[]>([]);
  const [generalConversations, setGeneralConversations] = useState<ChatConversation[]>([]);
  const [chatSelected, setChatSelected] = useState(false);
  const [selectedChatID, setSelectedChatID] = useState('');
  const [userID, setUserID] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMsg, setPopupMsg] = useState('');
  const [blockedUsers, setBlockedUsers] = useState<BlockedUser[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const getConversations = useCallback(() => {
    setIsLoading(true);
    chatService.getConversations().then((r) => {
      if(r && r.conversations) {
        setConversations(r.conversations);
      }
      if(r && r.general_conversations) {
        setGeneralConversations(r.general_conversations);
      }
      setIsLoading(false);
    })
  }, [])

  useEffect(() => {
    getConversations();
  }, [getConversations]);

  const getBlockedUsers = useCallback(() => {
    setIsLoadingUsers(true);
    chatService.getBlockedUsers().then((r) => {
      if(r && r.block_users) {
        setBlockedUsers(r.block_users);
        setIsLoadingUsers(false);
      }
    })
  }, [])

  useEffect(() => {
    getBlockedUsers();
  }, [getBlockedUsers]);

  const selectChat = (chat: ChatConversation) => {
    chatService.joinConversation({conversation_id: chat.id}).then((r) => {
      if(!r.error) {
        setSelectedChatID(chat.id);
        setChatSelected(true);
        setUserID(r.user_id);
      }
    })
  }

  const leaveChat = () => {
    chatService.leaveConversation(selectedChatID).then((r) => {
      setUserID('');
      setSelectedChatID('');
      setChatSelected(false);
      getConversations();
    })
  }

  const startDM = (e: any) => {
    if(e && e.action && e.action === 'startDM') {
      chatService.startDM({dm_user_id: e.message.senderId}).then((r) => {
        if(r && !r.error) {
          setSelectedChatID(r.conversation_id);
        }
        if(r && r.error) {
          setPopupMsg(r.message);
          setShowPopup(true);
        }
      })
    }
    if(e && e.action && e.action === 'blockUser') {
      chatService.blockUser({block_user: e.message.senderId}).then((r) => {
        setPopupMsg(r.message);
        setShowPopup(true);
      })
    }
  }

  const supportChat = () => {
    chatService.startDM({dm_user_id: 'support'}).then((r) => {
      if(r && !r.error) {
        setSelectedChatID(r.conversation_id);
        setChatSelected(true);
        setUserID(r.user_id);
      }
      if(r && r.error) {
        setPopupMsg(r.message);
        setShowPopup(true);
      }
    })
  }

  const unblockUser = (id: string) => {
    chatService.unblockUser({user_id: id}).then((r) => {
      getBlockedUsers();
      setPopupMsg(r.message);
      setShowPopup(true);
    })
  }

  return (
    <div style={{paddingTop: '50px'}}>
      <AppHeader title={'Chat'}/>
      <div style={{minHeight: '100vh'}}>
        {chatSelected &&
          <div>
            <Session
              // @ts-ignore
              appId={process.env.REACT_APP_TALKJS_ID}
              userId={userID}
            >
              <Chatbox
                asGuest={true}
                conversationId={selectedChatID}
                style={{ height: 600 }}
                onCustomMessageAction={(e) => startDM(e)}
              />
            </Session>
            <div style={{marginTop: '16px', display: 'flex', justifyContent:'center'}}>
              <Button className='chat-leave-btn' onClick={() => leaveChat()}>Leave</Button>
            </div>
          </div>
        }
        {!chatSelected &&
          <div>
            <div className={'chat-rooms-section'}>
              <div className='chat-header'>Discord</div>
              <a style={{textDecoration: 'none'}} href={'https://discord.gg/bCfmU5pNZW'}>
                <div className='chat-room-container-discord'>
                <div>
                  <img style={{marginRight: '12px', marginTop: '4px'}} width={25} src={'./discord-mark-white.svg'} alt={'discord logo'} />
                </div>
                <div>
                  Join Discord
                </div>
              </div></a>
            </div>
            <div className={'chat-rooms-section'}>
              <div className='chat-header'>General Rooms</div>
              {isLoading && <div style={{textAlign: 'center'}}><CircularProgress style={{'color': '#E81961'}} /></div>}
              {!isLoading &&
                <div>
                  {generalConversations.map((gc) =>
                    <div key={gc.id} onClick={() => selectChat(gc)} className='chat-room-container'>
                      Join {gc.subject}
                    </div>
                  )}
                </div>
              }
            </div>
            <div className={'chat-rooms-section'}>
              <div className='chat-header'>Private Rooms</div>
              {isLoading && <div style={{textAlign: 'center'}}><CircularProgress style={{'color': '#E81961'}} /></div>}
              {!isLoading &&
                <div>
                  <div onClick={() => supportChat()} className='chat-room-container'>
                    Contact Support
                  </div>
                  {conversations.map((c) =>
                    <div key={c.id}  onClick={() => selectChat(c)} className='chat-room-container' style={{marginBottom: '24px'}}>
                      Join {c.subject}
                    </div>
                  )}
                  {conversations.length === 0 &&
                    <div className='chat-txt' style={{marginBottom: '24px'}}>
                      No Private Rooms available
                    </div>
                  }
                </div>
              }
            </div>
            <div className={'chat-rooms-section'}>
              <div className='chat-header'>Blocked Users</div>
              {isLoadingUsers && <div style={{textAlign: 'center'}}><CircularProgress style={{'color': '#E81961'}} /></div>}

              {!isLoadingUsers &&
                <div>
                  {blockedUsers.map((bu) =>
                    <div key={bu._id} className={'chat-blocked-users-container'}>
                      <div>
                        {bu.display_name}
                      </div>
                      <div>
                        <Button onClick={() => unblockUser(bu._id)} className={'chat-blocked-users-btn'}>Unblock</Button>
                      </div>
                    </div>
                  )}
                  {blockedUsers.length === 0 &&
                    <div className='chat-txt' style={{marginBottom: '24px'}}>
                      No Blocked Users
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        }
      </div>

      <Snackbar
        open={showPopup}
        autoHideDuration={3000}
        onClose={() => setShowPopup(false)}
        message={popupMsg}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />
    </div>
  )
}