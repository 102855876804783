import React from "react";
import { Button } from "@mui/material";
import "./notificationsPurchaseStreaks.scss";
import { telemetryService } from "../../services/telemetry";

function NotificationsPurchaseStreaks(props: any) {

  const processClick = () => {
      telemetryService.recordTelemetry('reward_play_agree').then((r) => {
        if(!r.error) {
          if(props.closeDialog) {
            props.closeDialog();
          }
        } else {
          if(r.data.mark_notification_as_read) {
            if(props.closeDialog) {
              props.closeDialog();
            }
          }
        }
      })
    }
  

  return (
    <div className='notifications-purchase-streaks-container'>
      <div className='notifications-purchase-streaks-title'>
        Earn Vouchers and Gold Tickets for RewardedPlay Activity!
      </div>
      <div className='notifications-purchase-streaks-name'>Road to Gold Streak</div>
      <div className='notifications-purchase-streaks-description'>
        Make a purchase of ANY amount in ANY app that you installed through RewardedPlay and you will get a bonus RR travel voucher the next day. Do this for 7 days in a row and you will earn 1 gold ticket for keeping the streak alive!     
      </div>
      <div className='notifications-purchase-streaks-name'>Triple 5 Streak</div>
      <div className='notifications-purchase-streaks-description'>
        Install a new (to you) game through RewardedPlay and spend at least $5 within 5 days and get 5 GOLD TICKETS! It's that simple 5 in 5 for 5. See what we did there.
      </div>

      <div className='notifications-purchase-streaks-btns-container'>
        <Button onClick={() => processClick()} className='notifications-purchase-streaks-btn'>Let's Go!</Button>
      </div>
    </div>
  )
}
export default NotificationsPurchaseStreaks