import React, { useCallback, useEffect, useState } from "react";
import './notifications.scss';
import Loading from "../../components/Loading/Loading";
import AppHeader from "../../components/AppHeader/AppHeader";
import { notificationsService } from "../../services/notifications";
import { Pagination, Snackbar, Stack } from "@mui/material";
import NotificationsSmartTrade from "../../components/NotificationsSmartTrade/NotificationsSmartTrade";
import NotificationsManualTrade from "../../components/NotificationsManualTrade/NotificationsManualTrade";
import NotificationsGlobalCompletion
  from "../../components/NotificationsGlobalCompletion/NotificationsGlobalCompletion";
import NotificationsTradeSuccess from "../../components/NotificationsTradeSuccess/NotificationsTradeSuccess";
import NotificationsAchievement from "../../components/NotificationsAchievement/NotificationsAchievement";
import { AvailableNotification } from "../../types/notifications";
import { useNavigate } from "react-router-dom";
import { checkOffSeason } from "../../services/season";
import NotificationsArtifactTrade from "../../components/NotificationArtifactTrade/NotificationsArtifactTrade";

export function Notifications() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentNotification, setCurrentNotification] = useState({type: ''});
  const [page, setPage] = React.useState(1);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarTxt, setSnackBarTxt] = useState('');
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<AvailableNotification[]>([]);

  const getNotifications = useCallback(() => {
    setIsLoading(true);
    notificationsService.getNotifications(false).then((r) => {
      if(r && r.notifications) {
        setNotifications(r.notifications);
        if(r.notifications.length > 0) {
          setCurrentNotification(r.notifications[0]);
          if(r.notifications[0].type !== 'manual_trade' && r.notifications[0].type !== 'smart_trade' && r.notifications[0].type !== 'artifact_trade') {
            const notification_ids = [r.notifications[0]._id];
            notificationsService.markNotificationAsRead({notification_ids}).then((r) => {
              // console.log(r, 'r');
            })
          }
        }
        setIsLoading(false);
      } else {
        if (r.message && checkOffSeason(r.message.toString())){
          navigate(`/rest_area`);
        }
        setIsLoading(false);
      }
    })
  }, [navigate])

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    setCurrentNotification(notifications[value - 1]);
    if(notifications && notifications[value-1] && notifications[value-1].type && (notifications[value - 1].type !== 'smart_trade' && notifications[value - 1].type !== 'manual_trade' && notifications[value - 1].type !== 'artifact_trade')) {
      const notification_ids = [notifications[value - 1]._id];
      notificationsService.markNotificationAsRead({notification_ids}).then((r) => {
        // console.log(r, 'r');
      })
    }
  };

  const markNotification = (id: string, message?: string) => {
    const notification_ids = [id];
    notificationsService.markNotificationAsRead({notification_ids}).then((r) => {
      getNotifications();
      if(message) {
        setSnackBarTxt(message);
        setSnackBarOpen(true);
      }
    })
  }

  const markAllAsRead = () => {
    notificationsService.markAllAsRead().then((r) => {
      getNotifications();
      if (r && r.message) {
        setSnackBarTxt(r.message);
        setSnackBarOpen(true);
      }
    })
  }

  return (
    <div style={{paddingTop: '50px'}}>
      {isLoading && <Loading color={'#E81961'} />}
      {!isLoading &&
        <div style={{height: '100vh', background: '#FBF8EE'}}>
          <AppHeader title={'Notifications'}/>
          <div className={'notifications-container-header'}>
            <div style={{width: '300px', marginTop: '8px'}}>Notifications</div>
          </div>
          <div>
            {notifications.length > 0 ?
              <div>
                {currentNotification.type === 'smart_trade' && <div><NotificationsSmartTrade markAllAsRead={markAllAsRead} markNotification={markNotification} notification={currentNotification} /></div>}
                {currentNotification.type === 'manual_trade' && <div><NotificationsManualTrade markAllAsRead={markAllAsRead} markNotification={markNotification} notification={currentNotification} /></div>}
                {currentNotification.type === 'artifact_trade' && <div><NotificationsArtifactTrade markNotification={markNotification} notification={currentNotification} /></div>}
                {currentNotification.type === 'global_completion' && <div><NotificationsGlobalCompletion notification={currentNotification} /></div>}
                {currentNotification.type === 'trade_success' && <div><NotificationsTradeSuccess notification={currentNotification} /></div>}
                {currentNotification.type === 'achievement' && <div><NotificationsAchievement notification={currentNotification} /></div>}
                <Stack alignItems="center" style={{marginBottom: '16px'}}>
                  <Pagination sx={{button:{color: '#000'},'& .Mui-selected': {
                      backgroundColor: '#F3C42B'
                    },}} page={page} onChange={handlePageChange} count={notifications.length} size="small" />
                </Stack>
              </div> :
              <div className='notifications-empty-container'>
                No new notifications
              </div> }
          </div>
        </div>
      }
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackBarOpen(false)}
        message={snackBarTxt}
        ContentProps={{
          sx: {
            display: 'block',
            textAlign: "center"
          }
        }}
      />
    </div>
  )
}