import "./notificationsArtifactTrade.scss";
import { Button } from "@mui/material";
import { tradeService } from "../../services/trade";

function NotificationsArtifactTrade(props: any) {

  const processTrade = (state: string) => {
    const t = {
      artifact_trade_id: props.notification.metadata._id,
      status: state,
    }
    tradeService.completeArtifactTrade(t).then((r) => {
      if(!r.error) {
        props.markNotification(props.notification._id, r.message);
      } else {
        if(r.data.mark_notification_as_read) {
          props.markNotification(props.notification._id, r.data.message);
        }
      }
    })
  }

  return (
    <div className='notifications-artifact-trade-container'>
      <div>
        <div className='notifications-artifact-trade-title'>
          Someone notices you have something they are collecting
        </div>
        <div>
          <div className='notifications-artifact-img'>
            <img height={120} width={120} alt={props.notification.metadata.artifact_name} src={props.notification.metadata.image_url}  />
            {/* <div className='notifications-artifact-costs'>{props.notification.metadata.cost}</div> */}
            <div className='notifications-artifact-trade-subtitle'>{props.notification.metadata.artifact_name}</div>
          </div>
          <div className='notifications-artifact-trade-description'>
            You have {props.notification.metadata.user_artifact_count} of this artifact, do you want to trade {props.notification.metadata.cost} of them for {props.notification.metadata.reward_count} {props.notification.metadata.reward_type === 'voucher' ? "Bonus Travel Voucher" : "Golden Ticket"}?
          </div>
        </div>

        <div className='notifications-artifact-trade-btns-container'>
          <Button onClick={() => processTrade('rejected')} className='notifications-artifact-trade-btn'>Reject</Button>
          <Button onClick={() => processTrade('accepted')} className='notifications-artifact-trade-btn'>Accept</Button>
        </div>
      </div>
    </div>
  )
}
export default NotificationsArtifactTrade